<template>
    <el-row :gutter="160">
        <el-col :span="24" style="text-align: left;">
            <el-button type="primary" icon="el-icon-plus" @click="onClickAdd">创建兑换码</el-button>
            <el-button type="success" icon="el-icon-refresh" @click="refreshCdkeyList" style="margin-left: 8px;">刷新列表</el-button>
        </el-col>
        <el-col :span="24">
            <el-table :data="cdkeyList" :height="tableHeight" border style="width: 100%" :row-class-name="tableRowClassName"
                :cell-style="{padding:4+'px'}" class="serverlist">
                <el-table-column prop="id" label="id" width="60" show-overflow-tooltip> </el-table-column>
                <el-table-column prop="title" label="名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="channels" label="渠道" show-overflow-tooltip></el-table-column>                
                <el-table-column prop="items" label="物品内容" show-overflow-tooltip></el-table-column>
                <el-table-column prop="amount" label="生成数量" show-overflow-tooltip></el-table-column>
                <el-table-column label="多次领取" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.multi===0">否</span>
                        <span v-if="scope.row.multi===1">是</span>
                    </template>
                </el-table-column>
                <el-table-column prop="share" label="一码多用" show-overflow-tooltip></el-table-column>
                <el-table-column label="状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.state===0">生成中</span>
                        <span v-if="scope.row.state===1">已生成</span>
                        <span v-if="scope.row.state===2" style="color: #FF0000;">已作废</span>
                    </template>
                </el-table-column>
                <el-table-column label="过期时间" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="!scope.row.expire_time.length">永久</span>
                        <span v-if="scope.row.expire_time.length">{{scope.row.expire_time}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" label="创建时间" show-overflow-tooltip></el-table-column>
                <el-table-column label="兑换码文件" width="200">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="onDownloadCdkey(scope.row)" type="primary" icon="el-icon-download"
                            size="small" :disabled="1!=scope.row.state">导出</el-button>
                        <el-button @click.native.prevent="onDeprecatedCdkey(scope.row)" type="danger" icon="el-icon-delete"
                            size="small" :disabled="1!=scope.row.state">作废</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-col>
        <!-- ========弹框BEGIN====== -->
        <el-dialog title="创建兑换码" :visible.sync="dialogForm" width="760px" :close-on-click-modal="false" center>
            <el-form :model="form">
                <el-form-item label="名称" :label-width="formLabelWidth">
                    <el-input v-model="form.title" placeholder="请输入标题"></el-input>
                </el-form-item>
                <el-form-item label="领取渠道" :label-width="formLabelWidth">
                    <el-select
                    v-model="form.channels"
                    :multiple="true"
                    placeholder="不指定则全渠道可用" style="width: 100%;">
                        <el-option v-for="item in channels" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="一码多用" :label-width="formLabelWidth">
                    <el-row>
                        <el-col :span="4">
                            <el-form-item label="启用" label-width="60px">
                                <el-switch v-model="form.share" @change="onSelectShare"></el-switch>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                            <el-form-item label="限额" label-width="60px">
                                <el-input v-model="form.sharenum" placeholder="领取人数" :disabled="!cdkeyShareDis"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                            <el-form-item label="靓码" label-width="60px">
                                <el-input v-model="form.sharecode" placeholder="自定义码" :disabled="!cdkeyShareDis"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="多次领取" :label-width="formLabelWidth">
                    <el-switch v-model="form.multi" @change="onSelectMulti" :disabled="cdkeyShareDis"></el-switch>
                </el-form-item>
                <el-form-item label="选择配置" :label-width="formLabelWidth">
                    <el-select v-model="form.cfgver" @change="onSelectPkg" placeholder="请选择配置文件" style="width:100%;">
                        <el-option v-for="item in pkgList" :key="item.id" :label="item.name" :value="item.id">
                            <i class="el-icon-folder"></i>
                            <span>{{item.name}}</span>
                            <span style="float:right; color: #8492a6;">{{item.tag}}</span>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择物品" :label-width="formLabelWidth">
                    <el-button type="primary" v-for="node in itemConfigs" :key="node.type" @click="onSelectItem(node)">
                        {{node.label}}
                    </el-button>
                </el-form-item>
                <el-form-item label="生成数量" :label-width="formLabelWidth">
                    <el-input v-model="form.amount" placeholder="兑换码个数" :disabled="cdkeyShareDis"></el-input>
                </el-form-item>
                <el-form-item label="过期时间" :label-width="formLabelWidth">
                    <el-date-picker v-model="form.expire_time" type="datetime" placeholder="设置过期时间, 不指定则无限期" style="width:300px;">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" icon="el-icon-check" @click="onAddCdkey">确 定</el-button>
                <el-button icon="el-icon-close" @click="dialogForm = false">取 消</el-button>
            </div>
        </el-dialog>
        <!-- ========弹框END====== -->
        <!-- ========物品弹框====== -->
        <el-dialog :title="titleItemForm" :visible.sync="dialogItemForm" width="70%" :close-on-click-modal="false" :show-close="false"
            center class="itemdialog">
            <div style="width: 100%">
                <el-row>
                    <el-col :span="11">
                        <el-input v-model="search" placeholder="关键字搜索" style="width:100%;"></el-input>
                    </el-col>
                    <el-col :span="2">
                        <el-button type="success" plain style="width:100%;" disabled>&gt;&gt;&gt;&gt;&gt;&gt;</el-button>
                    </el-col>
                    <el-col :span="11">
                        <el-button type="primary" style="width:100%;" disabled>已选择的项</el-button>
                    </el-col>
                </el-row>
                <el-row :gutter="0">
                    <el-col :span="11">
                        <el-table :data="filterItems" border style="width: 100%" height="420" class="serverlist">
                            <el-table-column label="ID" prop="id" width="150"></el-table-column>
                            <el-table-column label="名称" prop="name" show-overflow-tooltip></el-table-column>
                            <el-table-column label="品质/等级" prop="lev" show-overflow-tooltip></el-table-column>
                            <el-table-column label="标签" prop="tag" show-overflow-tooltip></el-table-column>
                            <el-table-column label="描述" prop="desc" show-overflow-tooltip></el-table-column>
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <el-button size="mini" type="primary" @click="onAddItem(scope.row)">添加</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                    <el-col :span="2">
                        <el-divider direction="vertical"></el-divider>
                    </el-col>
                    <el-col :span="11">
                        <el-table :data="cdkeyitems" border style="width: 100%" height="420" class="serverlist">
                            <el-table-column label="ID" prop="id" width="150"></el-table-column>
                            <el-table-column label="名称" prop="name" show-overflow-tooltip></el-table-column>
                            <el-table-column label="品质/等级" prop="lev" show-overflow-tooltip></el-table-column>
                            <el-table-column label="标签" prop="tag" show-overflow-tooltip></el-table-column>
                            <el-table-column label="自定义参数" width="100">
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.param" size="mini"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" width="220">
                                <template slot-scope="scope">
                                    <el-input-number v-model="scope.row.amount" size="mini" :min="1"></el-input-number>
                                    <el-button size="mini" type="danger" @click="onRemoveItem(scope.row)" style="float:right;">移除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                </el-row>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" icon="el-icon-check" @click="dialogItemForm=false">确 定</el-button>
            </div>
        </el-dialog>
        <!-- ========弹框END====== -->
    </el-row>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'
    import axios from 'axios'
    import tool from '../../tool.js'
    export default {
        components: {},
        props: ['pkgtype'],
        data() {
            return {
                pkgCenterApi: null,
                cdkeyList: [],
                search: '',
                pkgList: [], // 可选配置
                cfgItems: [], // 可选物品
                cdkeyitems: [], // 选择的物品
                itemConfigs: [], // CDKEY物品配置 
                dialogForm: false,
                dialogItemForm: false,
                titleItemForm: '',
                channels: [], // 可用渠道列表
                form: {
                    id: '',
                    title: '',
                    channels:[],
                    multi: false,
                    share: false,
                    sharenum: '',
                    sharecode: '',
                    amount: '',
                    cfgver: '',
                    expire_time: '',
                },
                formLabelWidth: '100px',
                tableHeight: document.documentElement.clientHeight-136, 
            }
        },
        created: function() {
            // 打包中心httl
            this.pkgCenterApi = axios.create({
                baseURL: this.$store.state.game.pkgurl,
                timeout: 15000
            });  
            this.itemConfigs = JSON.parse(JSON.stringify(this.$store.state.game.mailItems));
            for (let i = 0; i < this.itemConfigs.length; i++) {
                this.itemConfigs[i].items = [];
                this.itemConfigs[i].selected = [];
            }
            this.refreshCdkeyList()
            this.refreshPkgList()
        },
        computed: {
            ...mapGetters(
                ['gameHttp']
            ),
            // 物品关键词过滤显示
            filterItems() {
                return this.cfgItems.filter((data) => {
                    data.amount = 1; // 物品默认数量1
                    data.param = ""; // 自定义参数默认空串
                    // 已选择过的则不用再显示了
                    for (let i = 0; i < this.cdkeyitems.length; i++) {
                        if (data.id == this.cdkeyitems[i].id) {
                            return false;
                        }
                    }
                    // 没有搜索条件 或 包含搜索字则显示
                    return !this.search || (data.id.includes(this.search) || data.name.includes(this.search) ||
                        data.desc.includes(this.search) || data.tag.includes(this.search));
                })
            },
            // 一码多用禁用数量设置和多次领取
            cdkeyShareDis() {
                return this.form.share ? true : false;
            }
        },
        methods: {
            // 发送请求到打包中心
            pkgCenterHttp (cmd, data) {
                let rqs = JSON.parse(JSON.stringify(data));
                rqs.cmd = cmd;
                return this.pkgCenterApi({
                    method: 'post',
                    url: '/sygame',
                    data: rqs
                });
            },
            // 刷新列表
            refreshCdkeyList() {
                this.gameHttp(3021, {}).then((response) => {
                    this.cdkeyList = response.data.list;
                    this.channels = response.data.channels;
                });
            },
            // 配置包列表
            refreshPkgList() {
                this.pkgCenterHttp(1007, {
                    type: 'config'
                }).then((response) => {
                    this.pkgList = response.data.list
                })
            },
            // 弹出新增CDKEY
            onClickAdd() {
                this.dialogForm = true
                for (let k in this.form) {
                    this.form[k] = ''
                }
                this.form.channels = []; // 默认全渠道
                this.form.multi = false; // 默认不是多次领取
                this.form.share = false; // 默认不是一码多用                
                // 清空物品选择
                for (let i = 0; i < this.itemConfigs.length; i++) {
                    this.itemConfigs[i].items = [];
                    this.itemConfigs[i].selected = [];
                }
            },
            // 新境CDKEY
            onAddCdkey() {
                if (!this.form.title) {
                    this.$message.error('标题不能为空');
                    return;
                }
                if (0 == this.cdkeyitems.length) {
                    this.$message.error('物品不能为空');
                    return;
                }
                let amount = parseInt(this.form.amount);
                if (!amount || amount < 0) {
                    this.$message.error('生成数量有误');
                    return;
                }
                if (this.form.share) { // 一码多用判断
                    if (!this.form.sharecode || !this.form.sharenum || parseInt(this.form.sharenum) <= 0) {
                        this.$message.error("一码多用参数有误")
                        return;
                    }
                }
                this.dialogForm = false
                // 提取参数
                let rqs = {
                    channels: this.form.channels,
                    title: this.form.title,
                    cfgver: this.form.cfgver,
                    amount: this.form.amount,
                    multi: this.form.multi ? 1 : 0,
                    share: this.form.share ? 1 : 0,
                    sharenum: this.form.sharenum,
                    sharecode: this.form.sharecode,
                    expire_time: this.form.expire_time ? tool.dateFormat(this.form.expire_time) : '',
                    items: [],
                };
                // 物品信息提取
                for (let i = 0; i < this.itemConfigs.length; i++) {
                    let node = this.itemConfigs[i];
                    for (let j = 0; j < node.selected.length; j++) {
                        rqs.items.push({
                            id: node.selected[j].id,
                            count: node.selected[j].amount,
                            param: node.selected[j].param.trim(),
                            type: node.type
                        });
                    }
                }
                // 请求操作
                this.gameHttp(3023, rqs).then((response) => {
                    if (0 == response.data.errcode) {
                        this.refreshCdkeyList()
                        this.$message.success(response.data.errmsg)
                    } else {
                        this.$message.error(response.data.errmsg)
                    }
                })
            },
            // 选择指定pkg里的配置
            onSelectPkg(id) {
                for (let i = 0; i < this.itemConfigs.length; i++) {
                    let node = this.itemConfigs[i]
                    this.loadLuaItems(id, node);
                }
            },
            loadLuaItems(id, node) {
                this.pkgCenterHttp(3122, {
                    cfgver: id,
                    luafile: node.lua,
                    fields: node.fields,
                    load: node.load,
                    dbtable: node.dbtbl,
                }).then((response) => {
                    node.items = response.data.list;
                })
            },
            // 选择物品类型
            onSelectItem(item) {
                this.cfgItems = item.items;
                this.cdkeyitems = item.selected;
                this.dialogItemForm = true
                this.titleItemForm = item.label + '选择';
            },
            // 选择一码多用
            onSelectShare(state) {
                if (state) {
                    this.form.amount = 1;
                    this.form.multi = false;
                } else {
                    this.form.sharecode = '';
                    this.form.sharenum = '';
                }
            },
            // 选择多次领取
            onSelectMulti(state) {
                console.log("onSelectOneKey->", state);
            },
            // 添加物品到选择列
            onAddItem(row) {
                let item = JSON.parse(JSON.stringify(row))
                this.cdkeyitems.push(item)
            },
            // 移除选择的物品
            onRemoveItem(row) {
                for (let i = 0; i < this.cdkeyitems.length; i++) {
                    if (row.id == this.cdkeyitems[i].id) {
                        this.cdkeyitems.splice(i, 1);
                        break;
                    }
                }
            },
            // 下载cdkey文件
            onDownloadCdkey(row) {
                axios.get(row.url, {
                        responseType: "blob"
                    }).then(response => {
                        const blob = new Blob([response.data]);
                        const link = document.createElement("a");
                        link.href = URL.createObjectURL(blob);
                        link.download = row.title;
                        link.click();
                        URL.revokeObjectURL(link.href);
                    })
                    .catch(console.error);
            },
            // 作废CDKEY
            onDeprecatedCdkey(row) {
                this.$confirm('是否作废[ ' + row.title + ' ]?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.gameHttp(3024, {
                        id: row.id
                    }).then((response) => {
                        if (0 == response.data.errcode) {
                            this.$message.success("作废成功")
                            this.refreshCdkeyList()
                        }
                    })
                }).catch(() => {})
            },
            // 数据表色彩间隔效果     
            tableRowClassName({
                rowIndex
            }) {
                if (0 == rowIndex % 2) {
                    return 'warning-row';
                } else {
                    return 'success-row';
                }
            },
        },
    }
</script>

<style>
    .serverlist.el-table .warning-row {
        background: #f5f7fa;
    }

    .serverlist.el-table .success-row {
        background: #ffffff;
    }

    .serverlist.el-table {
        margin-top: 16px;
    }

    .serverlist {
        font-size: 12px;
    }

    .itemdialog .el-dialog__body {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
